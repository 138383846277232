















































import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator';
import { idKeyMapModule } from '@/store/modules/id-key-map';
import WeaponCount from '@/components/WeaponCount.vue';

@Component({
  components: { WeaponCount },
})
export default class WeaponPicker extends Vue {
  get allWeapons(): number[] {
    return idKeyMapModule.weaponIds;
  }
  get isInitialState(): boolean {
    return this.selectedWeapons.length === 0;
  }
  get weapons(): number[] {
    if (this.options.length > 0) {
      return this.allWeapons.filter((weaponId) => this.options.includes(weaponId));
    }
    return this.allWeapons;
  }

  @PropSync('value', {
    type: Array,
  })
  selectedWeapons!: number[];

  @Prop({
    type: Array,
    default: () => [],
  })
  options!: number[];

  @Prop({
    type: Object,
    default: () => ({}),
  })
  counts!: { [key: string]: number };

  isOpen = false;

  closeModal() {
    this.isOpen = false;
  }

  openModal() {
    this.isOpen = true;
  }

  selectAll() {
    this.selectedWeapons = this.weapons.map((weaponId) => weaponId);
  }

  toggleSelection(weaponId: number) {
    if (this.selectedWeapons.includes(weaponId)) {
      this.selectedWeapons = this.selectedWeapons.filter((w) => w !== weaponId);
      return;
    }
    this.selectedWeapons = [...this.selectedWeapons, weaponId];
  }

  initialize() {
    this.unselectAll();
  }

  unselectAll() {
    this.selectedWeapons = [];
  }

  @Watch('options')
  onOptionsUpdate() {
    this.initialize();
  }

  mounted() {
    if (!this.selectedWeapons) {
      this.initialize();
    }
  }
}
