







import Vue from 'vue';
import MainWeapon from '@/components/MainWeapon.vue';

export default Vue.extend({
  components: { MainWeapon },
  props: {
    weaponId: {
      type: Number,
      required: true,
    },
    count: Number,
  },
});
