var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('require-fetch-template',[(_vm.schedule)?_c('schedule-card',{attrs:{"schedule":_vm.schedule}}):_vm._e(),(_vm.shiftSummary)?[_c('div',{staticClass:"columns reverse-row-order"},[_c('div',{staticClass:"column is-9"},[_c('shift-details',{attrs:{"is-detail":true,"normalize-failed-game":_vm.normalizeFailedGame,"player-name":_vm.player && _vm.player.name,"shift-summaries":[
            Object.assign({}, {isGlobal: false},
              _vm.summary,
              {rescue: _vm.summary.player_rescue,
              death: _vm.summary.player_death}),
            Object.assign({}, {isGlobal: true},
              _vm.shiftSummary.global,
              {rescue: _vm.shiftSummary.global.rescue}) ]}})],1),_c('section',{staticClass:"column is-3 v-space-between-4"},[_c('div',[_c('h2',[_vm._v(" Main weapons "),(_vm.schedule.weapons.includes(-1))?_c('small',{staticClass:"pseudo-link",on:{"click":function($event){_vm.isWeaponsModalOpen = true}}},[_vm._v(" ("+_vm._s(_vm.shiftSummary.weapons.length)+" / "+_vm._s(_vm.availableWeapons)+") ")]):_vm._e()]),_c('b-modal',{attrs:{"active":_vm.isWeaponsModalOpen},on:{"update:active":function($event){_vm.isWeaponsModalOpen=$event}}},[_c('h2',[_vm._v("Weapons played")]),_vm._l((_vm.idOrderedWeapons),function(ref){
          var count = ref.count;
          var weaponId = ref.weapon_id;
return [_c('weapon-count',{key:weaponId,attrs:{"count":count,"weapon-id":weaponId}})]}),_c('h2',{staticStyle:{"margin-top":"1em"}},[_vm._v("Unused weapons")]),_vm._l((_vm.unusedWeaponIds),function(weaponId){return [_c('main-weapon',{key:weaponId,staticStyle:{"margin":"8px"},attrs:{"weapon-id":weaponId}})]})],2),_c('div',{staticClass:"table-wrap box is-fullwidth"},[_c('table',{staticClass:"is-hoverable is-fullwidth"},[_c('tbody',_vm._l((_vm.shiftSummary.weapons),function(weapon){return _c('tr',{key:weapon.weapon_id},[_c('td',[_c('div',{staticClass:"is-flex"},[_c('main-weapon',{attrs:{"weapon-id":weapon.weapon_id}})],1)]),_c('td',[_c('p',[_vm._v(" "+_vm._s(weapon.count)+" "),_c('small',[_vm._v(" ("+_vm._s(_vm._f("percentage")((weapon.count / (_vm.summary.clear_waves + _vm.summary.games - _vm.summary.clear_games))))+") ")])]),_c('p',{staticClass:"proportional-bar-chart-container"},[_c('weapon-proportional-bar-chart',{attrs:{"value":weapon.count,"max":_vm.shiftSummary.weapons[0].count,"weapon":weapon.weapon_id}})],1)])])}),0)])])],1),_c('div',[_c('h2',[_vm._v("Special weapons")]),_c('div',{staticClass:"table-wrap box is-fullwidth"},[_c('table',{staticClass:"is-hoverable is-fullwidth"},[_c('tbody',_vm._l((_vm.shiftSummary.specials),function(ref){
          var count = ref.count;
          var games = ref.games;
          var special_id = ref.special_id;
return _c('tr',{key:special_id},[_c('td',[_c('div',{staticClass:"is-flex"},[_c('weapon',{attrs:{"weapon-type":"special","weapon-id":special_id}})],1)]),_c('td',[_c('p',[_vm._v(" "+_vm._s(games)+" "),_c('small',[_vm._v("("+_vm._s(count)+")")]),_c('small',[_vm._v(" ("+_vm._s(_vm._f("percentage")((games / _vm.summary.games)))+") ")])]),_c('p',{staticClass:"proportional-bar-chart-container"},[_c('weapon-proportional-bar-chart',{attrs:{"value":games,"max":_vm.shiftSummary.specials[0].games,"weapon":special_id}})],1)])])}),0)])])])])]),_c('section',[_c('h2',[_vm._v("Results")]),_c('results',{attrs:{"hide-schedule-heading":true,"raw-results":_vm.shiftSummary.results,"show-more-link":_vm.showMoreLink}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }