


























































































































import { Component } from 'vue-property-decorator';
import MainWeapon from '@/components/MainWeapon.vue';
import RequireFetchBase from '@/components/RequireFetchBase.vue';
import RequireFetchTemplate from '@/components/RequireFetchTemplate.vue';
import Results from '@/components/Results.vue';
import ScheduleCard from '@/components/ScheduleCard.vue';
import ShiftDetails from '@/components/ShiftDetails.vue';
import Weapon from '@/components/Weapon.vue';
import WeaponCount from '@/components/WeaponCount.vue';
import WeaponProportionalBarChart from '@/components/WeaponProportionalBarChart.vue';
import { requireFetchComponentModule as state } from '@/store/modules/require-fetch-component';
import { playersModule } from '@/store/modules/players';
import { schedulesModule } from '@/store/modules/schedules';
import { hasRandomWeapon, isGrizzcoWeapon, percentage, toFixed, unique } from '@/helpers/helper';
import { rareWeaponIds, Schedule, UserData } from '@/types/salmon-stats';
import { idKeyMapModule } from '@/store/modules/id-key-map';

@Component({
  name: 'PlayerShifts',
  components: { MainWeapon, Weapon, RequireFetchTemplate, Results, ScheduleCard, ShiftDetails, WeaponCount, WeaponProportionalBarChart },
  filters: { percentage, toFixed },
  methods: { hasRandomWeapon },
})
export default class PlayerShifts extends RequireFetchBase {
  public normalizeFailedGame = false;
  public player: UserData | null = null;
  public schedule: Schedule | null = null;
  public isWeaponsModalOpen = false;

  get apiPath() {
    return `players/${this.playerId}/schedules/${this.scheduleId}`;
  }
  get availableWeapons() {
    const { weapons } = this.schedule!;
    if (weapons.includes(-2)) { // Golden "?"
      return rareWeaponIds.length;
    } else if (weapons.includes(-1)) { // Green "?"
      return idKeyMapModule.weaponIds.length - rareWeaponIds.length + 1;
    }

    return unique(weapons).length
  }
  get idOrderedWeapons() {
    return [...this.shiftSummary.weapons].sort((a: any, b: any) => a.weapon_id - b.weapon_id);
  }
  get playerId() {
    return this.$route.params.playerId;
  }
  get scheduleId() {
    return this.$route.params.scheduleId;
  }
  get shiftSummary() {
    return state.data;
  }
  get showMoreLink() {
    return {
      name: 'players.schedules.results',
      params: { scheduleId: this.scheduleId },
    };
  }
  get summary() {
    const summary = state.data.summary as Record<string, string>;
    const result: Record<string, string> = {};

    Object.entries(summary).forEach((pair) => {
      const [k, v] = pair;

      if (k.substr(0, 6) === 'player') {
        const key = k.substr(7);
        result[`team_${key}`] = v + summary[`others_${key}`];
        result[k] = v;
      } else {
        result[k] = v;
      }
    });

    return result;
  }
  get unusedWeaponIds() {
    const unusedWeaponds: number[] = [];
    const playedWeaponIds = (this.shiftSummary.weapons as Array<{ weapon_id: number }>).map((w) => w.weapon_id);
    if (!playedWeaponIds.some(isGrizzcoWeapon)) {
      unusedWeaponds.push(-1);
    }

    unusedWeaponds.push(...idKeyMapModule.weaponIds.filter((id) => !isGrizzcoWeapon(id) && !playedWeaponIds.includes(id)));

    return unusedWeaponds;
  }

  mounted() {
    state.fetch(this.apiPath);

    schedulesModule.fetchScheduleMetadata(this.scheduleId).then((schedule) => {
      this.schedule = schedule;
    });

    playersModule.fetchPlayer(this.playerId).then((player) => {
      this.player = player;
    });
  }
}
