














































































































































































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator';
import { Route, RawLocation } from 'vue-router';
import { mapGetters } from 'vuex';
import { PickByValue } from 'utility-types';

import { FilterType, ResultsFilter, SortByOptions, SortByOrderOptions, ResultsFilterQuery } from '@/types/salmon-stats';
import { translate } from '@/helpers/helper';

import FormField from '@/components/FormField.vue';
import WeaponPicker from '@/components/WeaponPicker.vue';

const allAvailableFilters: FilterType[] = [
  'is_cleared',
  'golden_egg',
  'power_egg',
  'events',
  'stages',
  'weapons',
  'special',
  'player_results',
];

export const fieldsWithout = (fieldsToIgnore: FilterType | FilterType[]): FilterType[] => {
  if (!Array.isArray(fieldsToIgnore)) {
    fieldsToIgnore = [fieldsToIgnore];
  }

  return allAvailableFilters.filter((field) => !fieldsToIgnore.includes(field));
};

export const createResultFilter = (): ResultsFilter => ({
  golden_egg: {},
  power_egg: {},
  stages: [],
  events: [],
  weapons: [],
  sort_by: undefined,
  sort_by_order: undefined,
});

export const filterToRequestParams = (filters: ResultsFilter): ResultsFilterQuery | null => {
  const params = {
    min_golden_egg: filters.golden_egg.min,
    max_golden_egg: filters.golden_egg.max,
    min_power_egg: filters.power_egg.min,
    max_power_egg: filters.power_egg.max,
    events: filters.events,
    weapons: filters.weapons,
    is_cleared: filters.is_cleared,
    special: filters.special,
    stages: filters.stages,
    sort_by: filters.sort_by,
    sort_by_order: filters.sort_by_order,
  };

  type RequestParamKey = keyof typeof params;

  (Object.keys(params) as RequestParamKey[])
    // Filters keys to remove
    .filter((key) => {
      if (params[key] === undefined || params[key] === null || params[key] === '') {
        return true;
      } else if (Array.isArray(params[key])) {
        return (params[key] as any[]).length === 0;
      }

      return false;
    })
    .forEach((key) => delete params[key]);

  return Object.keys(params).length === 0 ? null : params;
};

export const paginatorWithFilters = (route: Route, page: number, filters?: ResultsFilterQuery | null): RawLocation => {
  const query: { [key: string]: string } = {
    page: page.toString(),
  };

  const toRoute = {
    name: route.name!,
    query,
  };

  if (filters) {
    toRoute.query.filters = JSON.stringify(filters);
  } else if (filters !== null && route.query.filters) {
    // null filter means reset.
    toRoute.query.filters = route.query.filters as string;
  }

  return toRoute;
};

export const restoreFilters = (serialziedFilters: string): ResultsFilter => {
  const defaultFilter = createResultFilter();

  try {
    const filter = JSON.parse(serialziedFilters);
    const restore = <T>(key: keyof PickByValue<Required<ResultsFilter>, T>): T => filter[key] ?? defaultFilter[key];

    defaultFilter.is_cleared = restore<boolean>('is_cleared');
    defaultFilter.golden_egg.min = filter.min_golden_egg ?? defaultFilter.golden_egg.min;
    defaultFilter.golden_egg.max = filter.max_golden_egg ?? defaultFilter.golden_egg.max;
    defaultFilter.power_egg.min = filter.min_power_egg ?? defaultFilter.power_egg.min;
    defaultFilter.power_egg.max = filter.max_power_egg ?? defaultFilter.power_egg.max;
    defaultFilter.stages = restore<number[]>('stages');
    defaultFilter.weapons = restore<number[]>('weapons');
    defaultFilter.sort_by = restore<SortByOptions>('sort_by');
    defaultFilter.sort_by_order = restore<SortByOrderOptions>('sort_by_order');

    return defaultFilter;
  } catch (_) {
    return defaultFilter;
  }
};

@Component({
  name: 'results-filter',
  components: { FormField, WeaponPicker },
  computed: mapGetters('id-key-map', ['stageIds']),
  methods: { translate },
})
export default class ResultsFilterComponent extends Vue {
  @PropSync('value', {
    required: true,
    type: Object,
  })
  private filter!: ResultsFilter;

  @Prop({
    type: Array,
    default: () => allAvailableFilters,
    // validator: (fields: string[]) => fields.every((allAvailableFilters as string[]).includes),
    validator: (fields: string[]) => fields.every((field) => (allAvailableFilters as string[]).includes(field)),
  })
  private readonly availableFilters!: FilterType[];

  private isFilterAvailable(key: FilterType): boolean {
    return this.availableFilters.includes(key);
  }

  @Watch('filter.sort_by')
  private onChangeSortBy(newValue: boolean, oldValue: boolean): void {
    if (!oldValue) {
      this.filter.sort_by_order = 'desc';
    } else if (!newValue) {
      this.filter.sort_by_order = undefined;
    }
  }
}
