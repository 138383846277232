





















































import { Component, Vue } from 'vue-property-decorator';
import PlayerSearchResult from '@/components/PlayerSearchResult.vue';
import apiClient from '@/api-client';

const MAX_LENGTH = 15;

@Component({
  name: 'PlayerSearch',
  components: { PlayerSearchResult },
})
export default class PlayerSearch extends Vue {
  public maxLength = MAX_LENGTH;
  public isSearching = false;
  public hasSearchdOnce = false;
  public nameQuery = '';
  public playerNames = [];
  public registeredUsers = [];

  private isValidName(name: string): boolean {
    return !!name && name.length <= MAX_LENGTH;
  }

  public search() {
    if (!this.isValidName(this.nameQuery)) {
      return;
    }

    this.$router.push({
      query: {
        name: this.nameQuery,
      },
    });
    this.isSearching = true;

    apiClient
      .get('/players/search', {
        params: { name: this.nameQuery },
      })
      .then((res) => {
        this.hasSearchdOnce = true;
        this.playerNames = res.data.names;
        this.registeredUsers = res.data.users;
      })
      .finally(() => {
        this.isSearching = false;
      });
  }

  public mounted() {
    const nameQuery = this.$route.query.name as string;
    if (this.isValidName(nameQuery)) {
      this.nameQuery = nameQuery;
      this.search();
    }
  }
}
