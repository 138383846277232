






























import Vue from 'vue';

import PlayerAvatar from '@/components/PlayerAvatar.vue';
import { sortPlayersData } from '@/helpers/helper';
import RouterHelperMixin from '@/helpers/router-helper';
import { playersModule } from '@/store/modules/players';
import { UserData } from '@/types/salmon-stats';

type EggKind = keyof typeof classNameTable;

const classNameTable = {
  golden_eggs: 'golden-egg',
  power_eggs: 'power-egg',
} as const;
export const eggKinds = Object.keys(classNameTable) as EggKind[];

export default Vue.extend({
  components: { PlayerAvatar },
  mixins: [RouterHelperMixin],
  props: {
    isValidEvent: {
      type: Boolean,
      required: true,
    },
    recordType: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    records: {
      type: Object,
    },
  },
  computed: {
    record(): { id: number; golden_eggs: number; power_eggs: number; } {
      return this.records[this.recordType];
    },
  },
  methods: {
    getClassNames(isValidEvent: boolean, eggKind: keyof typeof classNameTable): string[] {
      const classNames: string[] = [classNameTable[eggKind]];

      if (this.recordType === eggKind) {
        classNames.push('highlight');
      } else {
        const alsoRecordsOf = eggKinds.filter((kind) => kind !== eggKind)
          .filter((otherKind) => this.records[otherKind][eggKind] === this.records[eggKind][eggKind]);

        if (alsoRecordsOf.includes(this.recordType as EggKind)) {
          classNames.push('highlight');
        }
      }

      return classNames;
    },
    getMembersMetadata(ids: string[]): UserData[] {
      const players = ids.map((id) => playersModule.players.get(id)).filter(Boolean) as UserData[];
      return sortPlayersData(players);
    },
  },
});
