













import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class FormField extends Vue {
  @Prop({
    type: String,
  })
  label?: string;
}
