














































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ResultsFilterController extends Vue {
  private isFilterVisible = false;

  private toggleVisibility(): void {
    this.isFilterVisible = !this.isFilterVisible;

    if (!this.isFilterVisible) {
      this.$emit('close');
    }
  }
}
