



































import { Component, Watch } from 'vue-property-decorator';
import FilterController from '@/components/FilterController.vue';
import RequireFetchTemplate from '@/components/RequireFetchTemplate.vue';
import ResultsFilterComponent, {
  createResultFilter,
  filterToRequestParams,
  paginatorWithFilters,
} from '@/components/ResultsFilter.vue';
import RequireFetchBase from '@/components/RequireFetchBase.vue';
import ScheduleCard from '@/components/ScheduleCard.vue';
import { requireFetchComponentModule as state } from '@/store/modules/require-fetch-component';
import { schedulesModule } from '@/store/modules/schedules';
import { parseRawSchedule, mapQueryParamsToApiPath } from '@/helpers/helper';
import { Pop } from '@/types/util';
import { ResultsFilter } from '@/types/salmon-stats';
import { RawLocation } from 'vue-router';

@Component({
  name: 'Schedules',
  components: { FilterController, RequireFetchTemplate, ResultsFilter: ResultsFilterComponent, ScheduleCard },
})
export default class Schedules extends RequireFetchBase {
  public currentPage = 1;
  public filters: ResultsFilter = createResultFilter();

  public get apiPath() {
    return mapQueryParamsToApiPath('schedules', this.$route.query);
  }
  public get state() {
    return state.data;
  }
  get schedules() {
    return state.data ? state.data.data.map(parseRawSchedule) : [];
  }

  public paginate(toPage: number) {
    this.$router.push(this.paginator(toPage));
  }

  public paginator(...args: Pop<Parameters<typeof paginatorWithFilters>>): RawLocation {
    return paginatorWithFilters(this.$route, ...args);
  }

  private fetch() {
    this.currentPage = parseInt(this.$route.query.page as string, 10) || 1;

    state.fetch(this.apiPath).then((res) => {
      if (!res) {
        return;
      }

      res.data.forEach((schedule: any) => schedulesModule.setScheduleData(schedule));
    });
  }

  private mounted() {
    this.fetch();
  }

  @Watch('$route')
  private onRouteChange() {
    this.fetch();
  }

  private search() {
    if (!this.paginator) {
      return;
    }

    const filters = filterToRequestParams(this.filters);

    this.$router.push(this.paginator(1, filters));
  }
}
