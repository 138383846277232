

















import { Component, Prop, Vue } from 'vue-property-decorator';

import { formatDateToMdhm, formatScheduleId, DateFormatter } from '@/helpers/helper';

@Component({
  name: 'Schedule',
})
export default class Schedule extends Vue {
  @Prop({ default: formatDateToMdhm, type: Function })
  dateFormatter!: DateFormatter;

  @Prop({ default: false })
  isLinkDisabled!: boolean;

  @Prop({ required: true })
  scheduleId!: string | Date;

  formatScheduleId = formatScheduleId;
}
