




import { isMaxHazard } from '@/helpers/helper';

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'HazardLevel',
})
export default class HazardLevel extends Vue {
  @Prop({ required: true, type: String })
  hazardLevel!: string;

  isMaxHazard = isMaxHazard;
}
