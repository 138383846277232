









import { Component, Prop, Vue } from 'vue-property-decorator';

type ChartKey =
  | 'rescue'
  | 'death'
  | 'boss-kill'
  | 'golden-egg'
  | 'power-egg'
  | 'player-1'
  | 'player-2'
  | 'player-3'
  | 'player-4'
  | 'rescue-death'
  | 'win-loss';

const chartKeys: ChartKey[] = [
  'rescue',
  'death',
  'boss-kill',
  'golden-egg',
  'power-egg',
  'player-1',
  'player-2',
  'player-3',
  'player-4',
  'rescue-death',
  'win-loss',
];

@Component({
  name: 'ProportionalBarChart',
})
export default class ProportionalBarChart extends Vue {
  @Prop({
    type: String,
    required: true,
    validator: (key: string) => (chartKeys as string[]).includes(key),
  })
  public chartKey!: ChartKey;

  @Prop({
    type: Number,
    required: true,
  })
  public value!: number;

  @Prop({
    type: Number,
    required: true,
  })
  public max!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  public fillRemainder!: boolean;

  get width(): string {
    return this.max === 0 ? '0' : `${(this.value / this.max) * 100}%`;
  }
}
